import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Mail, Facebook, Instagram, Youtube, Twitter } from "grommet-icons";
import PageSection from "../components/PageSection/PageSection";
import useSiteMetadata from "../components/SiteMetadata";
import getGenericPage from "./generic/genericPage";
import { color, breakpoint, headingFamily } from "../theme";
import NewsletterForm from "../components/NewsletterForm/NewsletterForm";
import WhatsappButton from "../components/WhatsappButton";

const ContactLinks = styled.div`
	max-width: 600px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;

	${breakpoint("phone")`
		flex-direction: column;
		justify-content: center;
		flex-direction: column-reverse
	`}
`;

const ContactLink = styled.a`
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	justify-content: center;
	align-items: center;
	${color("dark-2")}
	margin-bottom: 40px;
	text-decoration: none;

	:hover {
		${color("brand")}
		font-weight: bold;
	}

	span {
		margin-top: 10px;
		font-size: 2rem;
		display: inline-block;
		${headingFamily}
	}
`;

const ContactSection = styled(PageSection)`
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ContactContent = () => {
	const { social, email } = useSiteMetadata();

	const links = [
		{ url: social["youtube"], icon: Youtube, title: "Youtube", color: "red" },
		{ url: social["twitter"], icon: Twitter, title: "Twitter", color: "#1da1f2" },
		{ url: social["instagram"], icon: Instagram, title: "Instagram", color: "#7D4CDB" },
		{ url: social["facebook"], icon: Facebook, title: "Facebook", color: "#3B5998" },
		{ url: `mailto:${email}`, icon: Mail, title: "E-mail", color: "neutral-1" },
	];

	return <>
		<ContactSection bottomBorder>
			<ContactLinks>
				{links.map((l) =>
					<ContactLink key={l.title} href={l.url} target="_blank" rel="noopener noreferrer">
						<l.icon size="large" color={l.color}/>
						<span>{l.title}</span>
					</ContactLink>)}
			</ContactLinks>
			<WhatsappButton/>
		</ContactSection>
		<PageSection>
			<NewsletterForm/>
		</PageSection>
	</>;
};

export const contactPageQuery = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      ...GenericPageFragment
    }
  highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 3) {
    edges {
      ...EventHighlight
    }
  }
}`;

export default getGenericPage(ContactContent, { showAd: true });

