import React from "react";
import styled from "styled-components";
import { WhatsappIcon } from "react-share";
import { color } from "../../theme";
import useSiteMetadata from "../SiteMetadata";

const StyledWhatsappButton = styled.div`
  width: 300px;
  background-color: #2cb742;
  padding: 20px;
  margin: 20px 0;
  font-size: 20px;
  border-radius: 2px;
  font-weight: bold;
  box-shadow: 0 0 4px 1px ${color("brand-dark", false)};

  a {
    ${color("white")}

    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 20px;
    }
  }
`;

const WhatsappButton = () => {
	const { social } = useSiteMetadata();

	return (<StyledWhatsappButton>
		<a href={social.whatsapp} target="_blank" rel="nofollow noopener noreferrer">
			<span>
					הצטרפו אלינו לקבוצה (שקטה) לעדכונים, הטבות, ותכנים.
			</span>
			<WhatsappIcon size={60} color="white"/>
		</a>
	</StyledWhatsappButton>);
};

export default WhatsappButton;
